.saisie-rdv-container {
    padding: 20px;
    background-color: #f0ffff;
    border-radius: 10px;
    border: 1px solid bisque;    
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    margin: 30px auto;
  }
  .saisie-rdv-container h2{
    text-align: center;
    color:#ff69b4 ;
  }
  

.bloc-identité-rdv{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.input-date-heure-form{ 
    margin-left: 10px;   
    border: 1px solid bisque;    
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

.input-label-rdv input{ 
    margin-left: 1px; 
    width: 50%;
    border: 1px solid bisque;    
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}    

#select-presta  {   
    width: 150px;
    font-size: 0.9rem;
}

.bloc-prestation p{
 text-align: center;
 color: blueviolet;
}
.select-employe-form{ 
    margin-left: 5px; 
    height: 30px;  
    border: 1px solid bisque;    
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

.label-presta-choix{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.label-presta-choix select{
    padding-top: 5px;
    height: 110px;
    width: 120px;
    border: 1px solid bisque;    
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

.choix-totaux{
    text-align: center;
}
.btn-choix-presta{
    margin: 10px 10px;
    display: flex;
    justify-content: space-around;
}

.btn-choix-presta button{
    width: 25%;
    border: 1px solid bisque;    
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

.btn-form-rdv{
    margin: 10px 10px;
    display: flex;
    justify-content: space-around;
}

.btn-form-rdv button {
    border: 1px solid bisque;    
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
} 