.menu-client-selectionne{
    overflow-y: auto;
}


.text-fonction-menu{
    color: dimgrey;
    font-size: 1.2rem;
}

.bloc-fiche-ident{
    margin-left: 8px;
    font-size: 1rem;
    
}


.champ-affichage-selection-client{    
    padding-left: 10px;
    color: black;
    font-weight: 700;
}

.champ-affichage-selection-client span{
    font-size: 1.1rem;
    color: #9e4dd9;
    font-weight: 700;
}

.bloc-btn-gestion-client{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.btn-affichage-selection-client{
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.btn-affichage-selection-client button{
    border: 1px solid bisque;
    width: 200px;
    background-color: rgb(135, 208, 242);
    color: black;
    font-size: 0.9rem;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

.btn-affichage-selection-client button:hover{    
    background-color: rgb(231, 214, 236);
}

