/* ClientsManager.css */
.client-search-search{  
    width: 98%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px ;
    box-sizing: border-box;
    background-color: #f0ffff;
    border: 1px solid bisque;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    margin: 20px auto;
    padding-bottom: 20px;
    border-radius: 10px;
}


.bloc-form-search{
  margin: 15px 15px;
  width: 25%;
}

  
  .table-container {
    max-height: 400px;
    width: 100%; 
    display: flex;
    overflow-y: auto;
    justify-content: center;
  }

  .clients-table-search {
    width: 75%;
    border-collapse: collapse;
    
  }
  
  .clients-table-search th,
  .clients-table-search td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  .clients-table-search th {
    background-color: #20b2aa;
  }

  .form-search input{
        width: 100%;
        border: 1px solid bisque;    
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  }
  .form-search button{
    margin-top: 10px;
    width: 40%;
    background-color: #9fcbf2;
    border: 1px solid bisque;    
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
 }

 .form-search button:hover{
    margin-top: 10px;
    background-color: rgb(231, 214, 236);
 }

  .btn-select-search {
    background-color: #4CAF50;
    width: 120px; 
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 5px;
  }

  .clients-table-search button{
    background-color: #9fcbf2;
    border: 1px solid bisque;    
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .clients-table-search button:hover{
    background-color: rgb(231, 214, 236);
  }

  