.navigation-entete{
  margin: 3px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
}

.bloc-logo-entete{
  width: 13%;
  display: flex;
  justify-content: center;
}

.logo-alyce{
  width: 60%;
}

.navbar-entete {
    background-color: #a6cdf5;
    border-radius: 5px;   
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    width: 86%;
    height: 50px;
    box-sizing: border-box;
    border:2px solid rgb(239, 199, 145);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .bloc-ul-entete{
   width: 100%;
    
  }

  .nav-menu-entete {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    
  }
  
  .nav-item-entete {
  }
  
  .bloc-btn-entete{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .btn-entete{
    background-color: rgb(135, 208, 242);
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
    border-radius: 5px;
    border:2px solid rgb(239, 199, 145);    
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  }

  #calendrier-entete:hover,
  .icon-navigation:hover{
    color: #4e4447;
    cursor: pointer;
  }


  .icon-navigation{
    color: #fff;
    font-weight: bold;
    font-size: 25px;
  
}

.bloc-btn-entete{
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.personnel-entete{
  font-size: 1.3rem;
  color:#ff69b4 ;
}

.objectif-entete{
  color: black;
  margin-left: 5px;
}

.color-rouge{
  color: crimson;
  margin-left: 5px;
  font-size: 1.2rem;
}

.color-vert{
  color: green;
  margin-left: 5px;
  font-size: 1.2rem;
}
.color-bleu{
  color: blue;
  margin-left: 5px;
  font-size: 1.2rem;
}
.notification-message{
  color: #f15281;
  font-weight: 800;
  font-size: 1.5rem;
}