.container-boite-reception {
    padding: 20px;
    border-radius: 5px;
    width: 94%;
    border: 1px solid bisque;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
}

.liste-messages {
    max-width: 400px;
    overflow-y: auto;
    border-bottom: 1px solid bisque;
    border-radius: 10px;
    margin-bottom: 20px;
}

.message-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.message-item:hover {
    background-color: #f9f9f9;
}

.message-objet-non-lu {
    font-weight: 900;
}
.message-objet-lu{
    font-weight: 300;
}

.message-info-date{
    color: #999;

}
.message-info-date span{
    font-weight: 800;
    margin-left: 3px;
}

.message-details {
    margin-top: 20px;
}

.message-details h3 {
    margin: 0 0 10px;
}

.message-details p {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.message-details button {
    margin-top: 10px;
}
