
.bloc-rdv-info{
   width: 100%; 
}


.bloc-rdv-info h3{
 margin-left: 8px;
}

.bloc-rdv-info h2{
    text-align: center;
    color: darkgray;
    text-transform: uppercase;
    font-size: 1.1rem;
}

.prothesiste-rdv,
.telephone-rdv,
.presta-rdv,
.horaire-rdv{
    margin-top: -10px;
    display: flex;
    flex-direction: column;
    color: darkorchid;
    font-size: 1.2rem;
    font-weight: 700;
}

.bloc-date {
  margin-left: 30px;
}

.bloc-debut-fin {
    margin-left: 30px;

}
.presta-rdv ul{ 
    margin-top: -2px;
    margin-left: 12px;
}
.span-presta-rdv-info{
    margin-left: 10px;
}

.telephone-rdv{
    margin-left: 40px;
}


.prothesiste-rdv{
    margin-left: 40px;
}

.nom-rdv{
    margin: 5px 0 ;
    color: white;
    text-align: center;
    font-weight:700;
    font-size: 1rem;
}

.bloc-btn-rdv{
    margin-top: 50px;
}

.btn-action-info-rdv{
    margin: 15px auto;
    width: 180px;
}

.btn-action-info-rdv button{
    width: 100%;
    border: 1px solid bisque;
    box-shadow: 5px 5px 10px rgb(0, 0, 0, 0.2);
}


@media (max-height: 880px) {
    
.prothesiste-rdv,
.telephone-rdv,
.presta-rdv,
.horaire-rdv{
    margin-top: -15px;
    display: flex;
    flex-direction: column;
    color: darkorchid;
    font-size: 1rem;
    font-weight: 700;
}
.bloc-rdv-info fieldset{
    padding-top: -5px;
}

.bloc-info-field{
    margin-top: 10px;
}
}