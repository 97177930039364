.memo-manager {
    color: #800080;
    padding: 20px;
    border-radius: 15px;
    width: 94%;
    height: 80%; 
    overflow-y: auto;
    margin: 0px auto;
  }

  .memo-affich{
    margin-top: -12px;
    padding-top: -5px;    
    height: 90%; 
    overflow-y: auto;
  }
  
 .memos-list h2 {
    text-align: center;
    color: #945074;
    font-size: 1rem;
  }
  
  .add-memo, 
  .edit-memo,
  .memos-list {
    margin-bottom: 10px;
  }
  
  textarea {
    box-sizing: border-box;
    width:100%;
    height: 100px;
    padding: 10px;
    border: 2px solid bisque;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-bottom: 10px;
    font-size: 1rem;
  }

  .add-memo  button {
    width: 120px;
    height: 35px;
    border: 1px solid bisque;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);

  }
 
  
   button {
    background: #8db5c5;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  button:hover {
    background: #f5a8d3;
  }

  .div-memo-map{
    padding: 1px 10px;
    margin: 1px auto;
  }

  .memo-item {
    background: #fff;
    border: 1px solid bisque;
    border-radius: 10px;
    padding: 15px 15px;
    margin-top: 15px;
    font-size: 1rem;
  }

  .btn-modif-supp{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 5px 15px;
  }

  .btn-modif-supp button{
        width: 100px;
        height: 35px;
  }
  
  
  .memo-item p {
    margin: 5px 0;
    font-size: 1rem;
  }
  
  .memo-item button {
    margin-right: 10px;
  }

  
  .prompt-memo-client{
    font-family: 'Comic Sans MS', cursive, sans-serif;
    color: black;

  }