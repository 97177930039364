.bloc-memo-modif{
    width: 75%;
    margin: 25px auto;
    overflow-y: auto;
}

.list-memo-modif{
    width: 100%;
}

.list-memo-modif h2{
   text-align: center;
}

.memo-modif-item{
    background-color: aliceblue;
    padding: 15px 15px;
    border: 1px solid bisque;
    border-radius: 10px;    
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
}

.memo-modif-item{
    color: blueviolet;
}


.prompt-memo-modif{
    font-family: 'Comic Sans MS', cursive, sans-serif;
    color: black;

  }

.btn-modif-supp{
    margin-bottom: 15px;
}

.btn-edit-Modi-annul-memo{
    display: flex;
    flex-direction: row;
    justify-content: space-around;

  }
  
  .btn-edit-Modi-annul-memo button{
    width: 100px;
  }
