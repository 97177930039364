.carousel {
    position: relative;
    width: 550px; /* Ajustez la largeur selon vos besoins */
    margin: -20px auto;
  }
  
  .carousel-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  
  .carousel-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .carousel-button {
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .prev-button {
    left: 10px;
  }
  
  .next-button {
    right: 10px;
  }
  
  .carousel-indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .indicator {
    width: 10px;
    height: 10px;
    background-color: gray;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .indicator.active {
    background-color: black;
  }
  