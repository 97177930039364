.clients-manager {
    width: 98%;
    height: 84vh;
    margin: 10px auto;
    padding: 5px 0px;
    background-color: #f0ffff;  
    border: 1px solid bisque;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    font-family: 'Arial', sans-serif;     
    overflow-y: auto; 
  }
  
  .clients-manager h1 {
    margin: 15px;
    text-align: center;
    color: #20b2aa; /* Vert d'eau */
  }

  .bloc-form-client{
    display: flex;
    justify-content: center;
    margin: 20px auto;
    width: 100%;
  }

  .clients-form{
    margin: 15px auto;
    width: 39%;
  }
  
  .clients-form input {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #5f9ea0;
    border-radius: 5px;
  }
  
  .clients-form button {
    margin: 10px;
    width: 101px;
    background-color: #5f9ea0;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid bisque;    
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .clients-form button:hover {
    background-color: #4682b4; /* Bleu acier */
  }

  .table-container-client{
    display: flex;
    justify-content: space-around;
    max-height: 69vh;
    margin-top: 5px;
  }

  .clients-table {
    width: 99%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .clients-table th, .clients-table td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }
  
  .clients-table th {
    background-color: #20b2aa;
    border: 1px solid rgb(135, 208, 242);
    color: white;
  }

  .clients-table tr:nth-child(even) {
    background-color: #f0fff0; 
  }

  .clients-table td {
    border: 1px solid rgb(135, 208, 242);
    padding: 10px;
    text-align: left;
  }
  
  .clients-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .edit-button {
    background-color: #5f9ea0;
    color: white;
    padding: 5px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .edit-button:hover {
    background-color: #4682b4;
  }
  
  .delete-button {
    background-color: #ff6347; /* Tomate */
    color: white;
    padding: 5px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 5px;
  }
  
  .delete-button:hover {
    background-color: #ff4500; /* Orange rouge */
  }
  
  .error {
    color: red;
    text-align: center;
  }
  
  .btn-table-client{
    /*text-align: center;
    width: 220px;*/
  }

  .btn-ajouter-client{
    margin-left: 40px;

  }

  .btn-ajouter-client button{  
    width: 180px;  
    border: 1px solid bisque;
    border-radius: 10px;    
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);

  }


  .bloc-modif-client{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }