 .bloc-menu-side-gauche {
            width: 100%;
            height: 100vh;
            margin: auto;
            padding-top: 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow-y: auto;
        } 




@media (min-width: 1480px) {
       

        .btn-affichage-menu-fonction{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 8px 0px;
        } 

        .bloc-menu-side-gauche legend{
            font-size: 1rem;
            background-color: rgb(241, 228, 248);
            color: black;
        }

        .btn-affichage-menu-fonction button{
            border: 1px solid bisque;
            width: 185px;
            background-color: rgb(135, 208, 242);
            color: black;
            font-size: 0.9rem;
            margin: 5px auto;
            box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
        }

        .btn-affichage-menu-fonction button:hover{    
            background-color: rgb(231, 214, 236);
        }

        .logo-jnp{ 
            margin: 10px auto;
        }

        .logo-jnp img{
            width:auto;
            height:30%;
        }

}

@media (min-width: 1200px) and (max-width: 1480px) {
    /*
    .bloc-menu-side-gauche {
        width: 100%;
        height: 100%;
        margin: auto;
        padding-top: 15px;
    } 
*/
    
    .btn-affichage-menu-fonction{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 8px 0px;
    } 

    .bloc-menu-side-gauche legend{
        font-size: 1rem;
        background-color: rgb(241, 228, 248);
        color: black;
    }

    .btn-affichage-menu-fonction button{
        border: 1px solid bisque;
        width: 130px;
        background-color: rgb(135, 208, 242);
        color: black;
        font-size: 0.9rem;
        margin: 3px auto;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    }

    .btn-affichage-menu-fonction button:hover{    
        background-color: rgb(231, 214, 236);
    }

    .logo-jnp{ 
        margin: 10px auto;
    }

    .logo-jnp img{
        width:auto;
        height:50%;
    }

}