.bloc-photo{
    display:flex;
    flex-wrap:wrap;
    gap: 10px;
}

.image-cliente{
    width: 700px;
    height: 600px;
    object-fit: cover;
}