
.bloc-page-connexion{  
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-image{
  margin-bottom: 50px ;
}

.login-container {     
    width: 300px;
    margin: 15px auto;
    padding: 20px;
    background-color: #f7f7ff;
    border: 1px solid bisque;
    border-radius: 10px;
    border: 1px solid rgb(249, 237, 101);
    box-shadow: 5px 5px 15px rgba(0, 0, 0.9);
    text-align: center;;
  }

  .login-container form{
    display: flex;
    flex-direction: column; 
    align-items: center;
  }

  .error-connexion{
    color: rgb(12, 12, 246);
  }
  
  h2 {
    color: #ff69b4;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  input[type='text'],
  input[type='password'] {
    width: calc(100% - 10px);
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  
  .btn-login button {
    width: 100%;
    padding: 10px;
    background-color: #97c2d2;
    border: 1px solid bisque;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 15px;
  }

  #btn-login-connexion{
    border: 1px solid bisque;
  }
  
  .btn-login button:hover {
    background-color: #e6c4cf;
  }

  .container-img-reglage{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 2px auto;

    /*;
    background-color: #97c2d2;
    border: 1px solid bisque;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    width: 120px;
    height: 38px;
    border-radius: 6px;*/
  }

  .container-img-reglage button{
      width: 100%;
      margin: auto 5px;
  }
  .adresse-input-connexion{
    margin: 10px auto;

  }

  
    .img-connexion{
    width: 30%;
    height: auto;
  }

  .container-img-reglage button :hover{
    background-color: #e6c4cf;
  }
  .header-connexion{
    width: 75%;
  }