.container-boite-envoie {
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 94%;
    margin: 0 auto;
}


.liste-messages {
    max-height: 300px;
    overflow-y: auto;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.message-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.message-item:hover {
    background-color: #f9f9f9;
}

.message-objet {
    font-weight: bold;
}

.message-date {
    color: #999;
}

.message-details {
    margin-top: 20px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.message-details h3 {
    margin: 0 0 10px;
}

.message-details p {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.message-details button {
    margin-top: 10px;
}
