body {
  font-family: Arial, sans-serif;
  margin:4px auto;
  padding: 0;
  background-color: #f4f4f4;
  height: 100%;
}


.home, .presentation, .service, .gallery, .contact, .connexion {
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  height: 98vh;
  width: 80%;
  border: 1px solid yellow;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}


.container-navbar{
  display: flex; /* Utilisation de Flexbox */
  flex-direction: column; /* Alignement en colonne */
  height: 50px;
  width: 90%;
  margin: 10px auto;
}

.navbar {
  display: flex; /* La barre de navigation utilise Flexbox */
  align-items: center; /* Aligne verticalement les éléments */
  justify-content: center; /* Centrer les éléments horizontalement */
  width: 100%; /* Prendre toute la largeur du conteneur */
  height: 100%; /* Prendre toute la hauteur du conteneur */
  background-color: #77F;
  color: #fff;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: 800;
  border: 1px solid yellow;
  box-shadow: 5px 5px 15px rgba(0, 0, 0.9);
  padding: 15px; 
}

.navbar ul {
  display: flex; /* Les éléments de la liste sont en ligne */
  list-style: none; /* Supprimer les puces de la liste */
  margin: 0;
  padding: 0;
  width: 100%; /* La liste prend toute la largeur */
  justify-content: space-around; /* Espacement égal entre les éléments */
}

.navbar li {
  flex: 1; /* Chaque élément occupe une partie égale de la largeur */
  text-align: center; /* Centrer le texte */
}

.navbar a {
  color: white; 
}

.navbar a.active {
  font-weight: bold;
  color: aqua;
}

.navbar a:hover {
  text-decoration: underline;
  color: #333;
}


.logo {
  width: 15%;
  display: block;
  margin: 18px auto;
  border: 1px solid yellow;
  box-shadow: 5px 5px 15px rgba(0, 0, 0.2);
}

.container-contenu{
 height: 75%;
}

.salon-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}


  .container-image{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
  }
  
  .container-bloc1{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
  }

  .bloc1-image{ 
    width: 20%;
    text-align: center;
 }
 .bloc1-image img{
  width: 100%;
    border: 1px solid rgb(190, 190, 178);
    box-shadow: 5px 5px 15px rgba(0, 0, 0.9);
 }


  .bloc1-texte{
    width: 40%;
    text-align: justify;
  }

  .paragraphe2{
      font-size: 1rem;
      text-align: justify;
  }

  .vernis{
      width: 30%;
      height: auto;
      border: 1 solid;
      border-radius: 50%;
  }

  .bloc2-image{
      width: 31%;
      text-align: center;
   }
   .bloc2-image img{
      border: 1px solid rgb(190, 190, 178);
      box-shadow: 5px 5px 15px rgba(0, 0, 0.9);
   }


   .bloc2-texte{
       width: 50%;
       text-align: justify;

   }

   .outils{
      width: 65%;
      height: auto;
      border: 1 solid;
      border-radius: 50%;
  }

   .paragraphe3{
       font-size: 1rem;
   }



  .container-footer{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: lightpink;
      height: 50px;
      margin-bottom: 10px;
  }
  .contenu-footer{
      color:black;
      font-size: 1.2rem;
  }
  
  .copyright{
      position: relative;;
      font-size: 1.5rem;
      top: 2px;
  }
  
  /*********************************scroll ****************************/
  .container-texte {
    margin-top: 25px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      box-sizing: border-box;
      margin-bottom: 8px;
    }
    
    .paragraphe1 {
      color:rgb(237, 46, 46);
      display: inline-block;
      padding-left: 100%;
      animation: scroll 18s linear infinite;
      font-size: 1.7rem;
    }
    
    @keyframes scroll {
      from {
        transform: translateX(0%);
      }
      to {
        transform: translateX(-100%);
      }
    }
  /*************************             *****************************/  
  




.img-presentation{
  display: flex;
  justify-content: center;
}
.img-presentation img {
    width: 29%;
    border: 1px solid yellow;
    box-shadow: 5px 5px 15px rgba(0, 0, 0.9);
}

.bloc-presentation-salon{
 text-align: center;
 line-height: 0.5rem;
 margin-bottom: 25px;
}

.bloc-apropos{
  margin: 5px 50px;
}

.bloc-equipe{
  margin: 10px 50px;
}
.bloc-equipe ul{
  line-height: 1.3rem;
}

/*************************             *****************************/  
.pied-page{
  width: 95%;
  margin: 0 auto;
  background-color: #77F;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid yellow;
  box-shadow: 5px 5px 15px rgba(0, 0, 0.9);
}
.pied-page-titre {
  margin: 5px auto;
  font-size: 1.3rem;
  font-weight: 700;
}
.pied-page-slogan {
  margin: 2px auto;
}


/*************************             *****************************/  
.bloc-service {
  margin-left: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px; /* Espace entre les éléments */
}

.bloc-service > div {
  width: 28%; /* Chaque bloc occupe environ la moitié de la largeur */
}

.bloc-service > div:nth-child(odd) {
  align-self: flex-start; /* Alignement à gauche pour les blocs impairs */
}

.bloc-service > div:nth-child(even) {
  align-self: flex-end; /* Alignement à droite pour les blocs pairs */
}

.img-service{
  width: 55%;
  border-radius: 50%;
}


.gallery-images{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 15px;
  margin: 15px;
}

.card-gallery-img img {
  width: 450px;   /* Largeur fixe */
  height: 350px;  /* Hauteur fixe */
  border: 1px solid yellow;
  box-shadow: 5px 5px 15px rgba(0, 0, 0.9);
  object-fit: cover;
}

.bloc-contact{
     height: 80vh;
     display: flex;
     justify-content: center;
     align-items: center;
}

.card-contact{
  margin:20px 60px;
  padding: 1px 30px;
  padding-bottom: 25px;
  width: 420px;
  border: 1px solid yellow;
  box-shadow: 5px 5px 15px rgba(0, 0, 0.9);
  border-radius: 15px;
}

.social-media{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.div-insta{ 
  width: 60px;
  height: 60px;
}
.div-facebook{
  width: 80px;
  height: 80px;
}

.img-facebook{
  width: 100%;
  height: auto;
}
.img-insta{
  width: 100%;
  height: auto;
}