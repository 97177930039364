

.container-boite-comm{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
}
.boite-comm{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.boite-reception,
.boite-envoie {
    width: 45%;
    margin: 5px auto;
    padding: 20px;
    border-radius: 10px;
    background: #f0fff0;
    border: 1px solid bisque;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #9e4dd9; 
    height: 30vh;
    overflow-y: auto;
}
/*
*/
.bar-nav-comm{
    margin: 10px auto;
    padding: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border: 1px solid bisque;
    border-radius: 10px;
    background-color: #f0fff0;
    box-sizing: border-box;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.btn-gestion-comm button{
    width: 190px;
    margin: 5px auto;
}
