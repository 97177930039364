
@media (min-width: 1480px) {  
        
    
.user-card {
    background-color: #f5f7f7;
}

.bloc-page-courante{
    display: flex;
    justify-content: center;
}

.nav-side-gauche{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 85vh;
    overflow-y: auto;
}


.section-gauche{
    width: 18%;
    margin-left: 150px;    
    border: 1px solid bisque;   
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin: 3px;
    height: 90vh;
    background-color: #e6f2ff;
}

.section-centrale{
    margin: 5px 5px;
    width: 90%;
    height: 90vh;
    border: 1px solid bisque;   
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    background-color: f0ffff;
    border-radius: 8px;
}

.section-droite{
    width: 19%;   
    border: 1px solid bisque;   
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin: 3px 3px;
    height: 90vh;
    background-color: #e6f2ff;

}

.text-fonction-menu{
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
    color:dimgray;
    margin: 15px 0;

   
}

.nav-item{
    list-style: none;    
    margin: 0 10px;
}

.bloc-menu-side-gauche{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.menu-side-gauche-ul{
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    font-size: 1rem;
}

.nav-item:hover{
    color: #db7fad;
    cursor: pointer;
}

.bloc-menu-side-gauche legend {
    width: 120px;
  }

.bloc-menu-side-gauche fieldset {
    margin: 5px 15px;
}
}


@media (min-width: 1200px) and (max-width: 1480px) {
    .user-card {
        background-color: #f5f7f7;
    }
    
    .bloc-page-courante{
        display: flex;
        justify-content: center;
    }
    
    .nav-side-gauche{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }
    
    
    .section-gauche{
        width: 18%;
        height: 90.5vh;
        margin-left: 150px;    
        border: 1px solid bisque;   
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        margin: 3px;
        overflow-y: auto;
        background-color: #e6f2ff;
    }
    
    .section-centrale{
        margin: 5px 5px;
        width: 90%;
        height: 90vh;
        border: 1px solid bisque;   
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
        background-color: f0ffff;
        border-radius: 8px;
    }
    
    .section-droite{
        width: 19%;   
        border: 1px solid bisque;   
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        margin: 3px 3px;
        height: 90vh;
        background-color: #e6f2ff;
    }
    
    .text-fonction-menu{
        text-align: center;
        font-size: 1.5rem;
        text-transform: uppercase;
        color:dimgray;
        margin: 15px 0;
    }
    
    .nav-item{
        list-style: none;    
        margin: 0 10px;
    }
    
    .bloc-menu-side-gauche{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .menu-side-gauche-ul{
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 0px;
        font-size: 1rem;
    }
    
    .nav-item:hover{
        color: #db7fad;
        cursor: pointer;
    }
    
    .bloc-menu-side-gauche legend {
        width: 120px;
      }
    
    .bloc-menu-side-gauche fieldset {
        margin: 1px 15px;
    }
}

