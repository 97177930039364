.prestations-manager {
    width: 90%;
    margin: 20px auto;
    padding: 20px;
    background-color: #f0ffff;
    border-radius: 10px;   
    border: 1px solid bisque;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    font-family: 'Comic Sans MS', cursive, sans-serif;
    max-height: 80vh; 
    overflow-y: auto; 
  }
  
  .prestations-manager h1 {
    text-align: center;
    color:#ff69b4 ;
  }

  .prestations-form{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  
  .prestations-form input {
    width: 350px;
    padding: 10px;
    margin: 5px 0;   
    border: 1px solid bisque;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }


  .prestations-form textarea {
    width: 372px;
    padding: 10px;
    margin: 5px 0;   
    border: 1px solid bisque;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  
  .prestations-form button {
    width: 10%;
    padding: 10px;
    margin:10px 10px; 
    background-color: rgb(135, 208, 242);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .prestations-form button:hover {
    background-color: #088c90; /* Bleu clair */
  }

  .container-client-table{
    max-height: 80vh; 
    overflow-y: auto; 
    margin-top: 10px;
  }

  
  .prestations-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border-radius: 10px;
  }
  
  .prestations-table th{     
    border: 1px solid #20b2aa;
    padding: 10px;       
    text-align: center;
  }

  
  .prestations-table td {
    border: 1px solid #20b2aa;
    padding: 10px;
    text-align: left;
  }
  
  .prestations-table th {
    background-color: #20b2aa; /* Vert d'eau */
    color: white;
  }
  
  .prestations-table tr:nth-child(even) {
    background-color: #f0fff0; /* Vert d'eau clair */
  }

  
  
  .edit-button,
  .delete-button {    
    width: 35%;
    padding: 5px 10px;
    margin-top: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
  }
  .duree-table-prestation{
      text-align: center;
  }

  td .prix-table-prestation {
    background-color: #fff;
    vertical-align: middle;

  }
  .btn-table-prestation{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .edit-button {
    width: 85px;
    background-color: #87cefa; /* Bleu clair */
    color: white;   
    border: 1px solid bisque;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .edit-button:hover {
    background-color: #4682b4; /* Bleu plus foncé */
  }
  
.delete-button {
  width: 85px;
  background-color: #ee95a8;
  color: white;   
  border: 1px solid bisque;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

.delete-button:hover {
  background-color: #e00c3a;
}

  .error {
    color: red;
    text-align: center;
  }

  .btn-ajouter-prestation button{
    text-transform: capitalize;
    width: 220px;   
    border: 1px solid bisque;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  }

 .btn-gestion-prestation button{
      width: 150px; 
      background-color: rgb(135, 208, 242);
      color: black;  
      border: 1px solid bisque;
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  
 }
 .btn-gestion-prestation button:hover{   
    background-color: rgb(231, 214, 236);
  } 

