

.days-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Espace entre les blocs */
  }
  
  .day-block {
    flex: 1;
    width: 200px; /* Largeur minimale pour chaque bloc */
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .day-block h3 {
    margin-top: 0;
  }
  
  .day-block div {
    margin-bottom: 10px;
  }
  
  .day-block label {
    display: block;
    margin-bottom: 5px;
  }
  