

.card-logo-barber img{
    width: 340px;
    border: 1px solid yellow;
    border-radius: 10px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0.9);
    object-fit: cover;
}


.logo-barber{     
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 15px;
}