.objectifs-manager{
    max-width: 88%;
    margin: 20px auto;
    padding: 0px 20px;
    background-color: #f0ffff; 
    border-radius: 10px;   
    border: 1px solid bisque;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

.objectifs-manager h2 {
  text-align: center;
  color:#ff69b4 ;
  }

.container-objectifs-table{
    max-height: 80vh; 
    overflow-y: auto; 
    margin-top: 10px;
  }


  .objectif-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border-radius: 10px;
  }
  
  .objectif-table th{     
    border: 1px solid #20b2aa;
    padding: 10px;       
    text-align: center;
  }

  
  .objectif-table td {
    border: 1px solid #20b2aa;
    padding: 10px;
    text-align: center;
  }
  
  .objectif-table th {
    background-color: #20b2aa; /* Vert d'eau */
    color: white;
  }
  
  .objectif-table tr:nth-child(even) {
    background-color: #f0fff0; /* Vert d'eau clair */
  }

  .btn-objectifs{
    margin: 15px 15px;
    display: flex;
    justify-content: center;
  }

  .btn-objectifs button{
    width: 250px;
    border: 1px solid bisque;    
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  }
  .objectif-table input{
    width: 80px;
    text-align: center;   
    border: 1px solid bisque;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  }

  .annee-objectif select{
    width: 80px;
    height: 28px;
    border: 1px solid bisque;    
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  }

  
  .mois-objectif select{
      width: 80px;
      height: 28px;
      border: 1px solid bisque;    
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  }


  .bloc-annee-mois-objectif{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .objectifs-institut {
    color: blueviolet;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .montant {

  }