
.container-historique-client{
  background-color: #f0fff0;
  width: 94%;
  margin: 20px auto;
  border-radius: 10px;
  border: 1px solid bisque;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.container-historique-client h2 {
  text-align: center;
  color:#ff69b4 ;
  }

.bloc-prestation p{
  font-size: 1rem;
  font-weight: 600;

}

#select-historique  {   
    width: 200px;
    font-size: 0.9rem;
    border: 1px solid bisque;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
.input-date-historique{ 
  height: 35px;
  font-size:1rem; 
  border: 1px solid bisque;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
.btn-choix-presta-historique{  

}

  .btn-choix-presta-historique button{
    width: 110px;
    border: 1px solid bisque;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .bloc-date-presta-historique{
    margin: 25px auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  
  .bloc-final-historique{
    margin: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  #select-mode-paiement{ 
    font-size: 1rem;   
    border: 1px solid bisque;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .btn-historique-presta {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
  }

  .btn-historique-presta button{
    margin: 10px auto;
    border: 1px solid bisque;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .choix-totaux,
  .mode-paiement-historique{
    font-size: 1.1rem;    
  }

  .mode-paiement-historique span,
  .choix-totaux span{
    margin-left: 3px;
    color: blueviolet;
    font-weight: 600;
  }
  