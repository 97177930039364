.btn-rediger-message{
    width: 95%;
    margin: 5px auto;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid bisque;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    color: #9e4dd9; 
    height: 46vh;
}

.bloc-select-destinataire{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.bloc-saisie-message input{
    width: 200px;
}
.bloc-saisie-message textarea{
    margin: 10px auto;
    border: 1px solid bisque;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);

}
.bloc-select-destinataire select{
    margin-top: 10px;
    border: 1px solid bisque;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.bloc-saisie-message{
    margin: 20px auto;
}

.btn-gestion-comm button,
.btn-supprime-destinataire button,
.btn-envoie-message button{
    border: 1px solid bisque;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}