.employees-manager {
    width: 90%;
    max-height: 80vh;
    margin: 20px auto;
    padding: 20px;
    background-color: #f0ffff;   
    border: 1px solid bisque;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;     
    overflow-y: auto; 
  }
  
  .employees-manager h1 {
    text-align: center;
    color:#ff69b4 ;

  }
  
  .employees-form input {
    width: 60%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #4169e1;   
  }
  
  .employees-form button {
    width: 20%;
    padding: 10px;
    margin-top: 10px;
    background-color: #e1b2eb;   
    border: 1px solid bisque;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }

  .employees-form button:hover {
    background-color: #ee29ba;
  }

  .container-personnel-table{
    max-height: 80vh; 
    overflow-y: auto; 
    margin-top: 10px;
  }
  
  
.employees-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.employees-table th{     
  border: 1px solid rgb(135, 208, 242);
  padding: 10px;       
  text-align: center;
  background-color: #20b2aa; 
  color: white;
}


.employees-table td {
  border: 1px solid rgb(135, 208, 242);
  padding: 10px;
  text-align: center;
}


.employees-table tr:nth-child(even) {
  background-color: #f0fff0; 
}

.selection-fonction{
    width: 25%;
    font-size: 0.9rem;
    height: 36px;
    border-radius: 5px;
    border: 1px solid #4169e1;
}

.btn-gestion-personnel{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.btn-gestion-personnel button{
  width: 200px;  
  border: 1px solid bisque;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

.btn-ajouter-employe{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.btn-ajouter-employe .btn{
  width: 120px;
}

.employees-form button{
  margin: 10px;
  width: 10%;  
  border: 1px solid bisque;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

.btn-action-fonction{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.btn-action-fonction button{
  width: 100px;
  height: 35px;
}

.btn-ajout-fonction-personnel{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
}

.btn-ajout-fonction-personnel button{
  width: 120px;
  height: 35px;  
}

#saisi-fonction{
  width: 25%;
  border: 1px solid bisque;
}

.bloc-ajout-fonction input{
  margin-left: 25px;
}

.bloc-ajout-fonction label{
  text-align: left;
  margin-left: 70px;
}

.table-ajout-fonction{  
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table-ajout-fonction th{     
  border: 1px solid black;
  padding: 10px;       
  text-align: center;
  background-color: #20b2aa; 
  color: white;
}

.table-ajout-fonction td {
  border: 1px solid black;
  padding: 10px;
  text-align: center;
}

.table-ajout-fonction tr:nth-child(even) {
  background-color: #f0fff0; /* Vert d'eau clair */
}

/**************************************************************
          Gestion Fonction Personnel
**************************************************************/
 
.container-personnel-table p{
  font-weight: 600;
  color: #20b2aa;
  font-size: 1.1rem;
}

.fonction-personnel-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.fonction-personnel-table th{     
  border: 1px solid black;
  padding: 10px;       
  text-align: center;
  background-color: #20b2aa; 
  color: white;
}


.fonction-personnel-table td {
  border: 1px solid black;
  padding: 10px;
  text-align: center;
}

.fonction-personnel-table tr:nth-child(even) {
  background-color: #f0fff0; /* Vert d'eau clair */
}

#Manager{
  visibility: hidden;
}

.btn-action-fonction{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.btn-action-fonction button{
  margin-left: 5px;
  width: 100px;  
  border: 1px solid bisque;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

.couleur-personnel{
  margin-left: 4px;
  width: 55px;
  height: 25px;  
  border: 1px solid bisque;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}


.btn-gestion-fonction{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.btn-gestion-fonction button{  
  width: 100px;  
  border: 1px solid bisque;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

