.bloc-fiche-cliente{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
}
.client-profile-ident,
.client-profile {
    width: 40%;
    margin: 5px auto;
    padding: 20px;
    border-radius: 15px;
    background: #f0fff0;
    border: 1px solid bisque;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #9e4dd9; 
    height: 36vh;
  }

  .client-profile-ident h1 {
    text-align: center;
    color: #ff1493; /* Deep pink */
    font-size: 1.2rem;
    margin-top: -1px;
    margin-bottom: 10px;
  }

  .client-profile-ident p {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .client-profile-ident strong {
    color: black; 
    font-weight: 5s00;
  }
  


  
.btn-suivi-rdv{
  width: 30%;
  margin: 5px 25px;
  border: 1px solid bisque;
}

  .client-profile h1 {
    text-align: center;
    color: #ff1493; /* Deep pink */
    font-size: 1.2rem;
    margin-top: -5px;
    margin-bottom: 10px;
  }
  
  .client-profile p {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .client-profile strong {
    color: black; 
    font-weight: 5s00;
  }
  .bloc-client-details{
    display: flex;
    justify-content: center;
  }

  #fiche-client {
    margin-top:15px ;
    background-color: #ff69b4;
    height: 90%;

  }
  .client-details-identite{
    margin: 0 auto;
    background: #fff0f5; 
    border: 1px solid #ffb6c1; 
    border-radius: 10px;
    padding: 5px 25px;
    width: 75%;
    font-size: 0.9rem;
    height: 100%;
  }

  .client-details-historique{
    background: #fff0f5; 
    border: 1px solid #ffb6c1; 
    border-radius: 10px;
    padding: 5px;
    width:85%;
  }

  .client-profile .client-details {
    background: #fff0f5; 
    border: 1px solid #ffb6c1; 
    border-radius: 10px;
    padding: 5px;
    width: 65%;
  }
  
  .client-profile .client-details p {
    margin: 5px 0;
  }

  .client-profile .memo-client {
    height: 95%;
    width: 100%;    
    overflow-y: auto;
  }
  